<template>
  <Link :href="linkHref" @click="visitLink">
    <slot />
  </Link>
</template>

<script>
  import { Link } from '@inertiajs/inertia-vue3';

  export default {
    name: 'SubdomainLink',

    props: {
      href: String,
      root: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      Link,
    },

    computed: {
      currentSubdomain() {
        return this.getSubdomain(window.location.host);
      },

      linkedSubdomain() {
        return this.getSubdomain(this.href);
      },

      linkHref() {
        return this.root && this.currentSubdomain
          ? this.href.replace(`${this.currentSubdomain}.`, '')
          : this.href;
      },
    },

    methods: {
      getSubdomain(url) {
        url = url.replace('https://', '');
        let isLocal = url.indexOf('localhost') !== -1;
        let parts = url.split('.');
        let min = isLocal ? 1 : 2;
        return parts.length > min ? parts[0] : null;
      },

      visitLink() {
        if (
          (this.root && this.currentSubdomain) ||
          this.currentSubdomain !== this.linkedSubdomain
        ) {
          // Invalid subdomain link, force entire page visit/hard refresh
          window.location.href = this.linkHref;
        }
      },
    },
  };
</script>
