import { createApp, h } from 'vue';
import { App as InertiaApp, plugin as InertiaPlugin } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import SubdomainLink from '@/Components/SubdomainLink';

// Setup Vue App
const el = document.getElementById('app');

const app = createApp({
	render: () =>
		h(InertiaApp, {
			initialPage: JSON.parse(el.dataset.page),
			resolveComponent: (name) => import(`./Pages/${name}`).then((module) => module.default),
		}),
});

// Setup Global Helper Functions
const getSvgHtml = require.context('../svgs/', true, /\.svg$/);

app.config.globalProperties.$getSvgHtml = (name) => {
	return getSvgHtml(`./${name}.svg`);
};

app.config.globalProperties.$getIconHtml = (name, lib = null) => {
	let path = lib ? `./icons/${lib}/${name}.svg` : `./icons/${name}.svg`;
	return getSvgHtml(path);
};

// Setup Global Subdomain Link
app.component('subdomain-link', SubdomainLink);

// Configure and Mount Vue App
if (process.env.NODE_ENV === 'production') {
	Bugsnag.start({
		apiKey: 'a51f77fc89c2680508e184005687f626',
		plugins: [new BugsnagPluginVue()],
	});

	app.use(Bugsnag.getPlugin('vue'));
}

app.mixin({ methods: { route } }).use(InertiaPlugin).mount(el);

// Init InertiaProgress
InertiaProgress.init({ color: '#4B5563' });

window.buggin = () => {
	Bugsnag.notify(new Error('Test error'));
};
